<!-- ViewApprovalItemModal.vue -->
<template>
  <v-dialog v-model="dialog" max-width="800px">
    <v-card>
      <v-card-title class="headline primary white--text">
        View Approval Item
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-4">
        <v-row>
          <v-col cols="12" md="6">
            <h3 class="subtitle-1 font-weight-bold mb-2">General Information</h3>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td class="font-weight-medium">ID:</td>
                    <td>{{ item.id }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-medium">WddCode:</td>
                    <td>{{ item.WddCode }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-medium">Status:</td>
                    <td>
                      <v-chip
                        :color="getStatusColor(item.Status)"
                        text-color="white"
                        small
                      >
                        {{ getStatusName(item.Status) }}
                      </v-chip>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-medium">Remarks:</td>
                    <td>{{ item.Remarks || "N/A" }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-medium">Created:</td>
                    <td>{{ formatDateTime(item.CreateDate, item.CreateTime) }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-medium">Updated:</td>
                    <td>
                      {{ formatDateTime(item.UpdateDate, item.UpdateTime) || "N/A" }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>

          <v-col cols="12" md="6">
            <h3 class="subtitle-1 font-weight-bold mb-2">Approver Information</h3>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td class="font-weight-medium">Name:</td>
                    <td>{{ item.user.name }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-medium">Email:</td>
                    <td>{{ item.user.email }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-medium">Phone:</td>
                    <td>{{ item.user.phone_number }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>

          <v-col cols="12">
            <h3 class="subtitle-1 font-weight-bold mb-2">Stage Information</h3>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td class="font-weight-medium">Stage Name:</td>
                    <td>{{ item.stage.Name }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-medium">Stage Remarks:</td>
                    <td>{{ item.stage.Remarks }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-medium">Max Requesters:</td>
                    <td>{{ item.stage.MaxReqr }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-medium">Max Rejection Requesters:</td>
                    <td>{{ item.stage.MaxRejReqr }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>

          <v-col cols="12">
            <h3 class="subtitle-1 font-weight-bold mb-2">Document Confirmation</h3>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td class="font-weight-medium">Document Type:</td>
                    <td>{{ item.docs_confirmation.objecttype.DocumentName }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-medium">Draft Entry:</td>
                    <td>{{ item.docs_confirmation.DraftEntry }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-medium">Originator:</td>
                    <td>{{ item.docs_confirmation.originator.name }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-medium">Originator Email:</td>
                    <td>{{ item.docs_confirmation.originator.email }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-medium">Status:</td>
                    <td>
                      <v-chip
                        :color="getStatusColor(item.docs_confirmation.Status)"
                        text-color="white"
                        small
                      >
                        {{ getStatusName(item.docs_confirmation.Status) }}
                      </v-chip>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-medium">Remarks:</td>
                    <td>{{ item.docs_confirmation.Remarks }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  name: "ViewApprovalItemModal",
  props: {
    value: Boolean,
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    formatDateTime(date, time) {
      if (!date) return null;
      return moment(`${date} ${time || "00:00:00"}`).format("LLL");
    },
    getStatusName(status) {
      const statusMap = {
        Y: "Approved",
        N: "Rejected",
        W: "Pending",
      };
      return statusMap[status] || status;
    },
    getStatusColor(status) {
      const colorMap = {
        Y: "success",
        N: "error",
        W: "warning",
      };
      return colorMap[status] || "grey";
    },
  },
};
</script>
