<!-- FilterPanel.vue -->
<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header class="py-1">
        <v-row no-gutters align="center">
          <v-col cols="auto">
            <v-icon color="primary" class="mr-2">mdi-filter</v-icon>
          </v-col>
          <v-col>
            <span class="text-subtitle-1 font-weight-medium">Filters</span>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container fluid>
          <v-row dense>
            <v-col cols="12" sm="6" md="3">
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filters.startDate"
                    label="Start Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.startDate"
                  @input="startDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filters.endDate"
                    label="End Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.endDate"
                  @input="endDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-autocomplete
                v-model="filters.authorizerFrom"
                :items="users"
                label="Authorizer"
                item-text="name"
                item-value="id"
                dense
                outlined
                prepend-icon="mdi-account"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-autocomplete
                v-model="filters.originatorFrom"
                :items="users"
                label="Originator"
                item-text="name"
                item-value="id"
                dense
                outlined
                prepend-icon="mdi-account-edit"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row justify="end" class="mt-4">
            <v-col cols="auto">
              <v-btn text color="error" @click="clearFilters" class="mr-2">
                Clear Filters
                <v-icon right>mdi-filter-remove</v-icon>
              </v-btn>
              <v-btn color="primary" :loading="loading" @click="applyFilters">
                Apply Filters
                <v-icon right>mdi-filter-check</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "FilterPanel",
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      startDateMenu: false,
      endDateMenu: false,
      filters: {
        startDate: null,
        endDate: null,
        authorizerFrom: null,
        originatorFrom: null,
      },
    };
  },
  methods: {
    clearFilters() {
      this.filters = {
        startDate: null,
        endDate: null,
        authorizerFrom: null,
        originatorFrom: null,
      };
      this.$emit("clear-filters");
    },
    applyFilters() {
      this.$emit("apply-filters", this.filters);
    },
  },
};
</script>
